import React from 'react';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src='cat400px.png' className='App-logo' alt='logo' />
        <h2 style={{ marginTop: "5rem" }}>mackovic.dev</h2>
        <p>UNDER CONSTRUCTION!!</p>
      </header>
    </div>
  );
}

export default App;
